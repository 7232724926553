import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import { getOrdersByCustomer } from '../../services/OrderManagementService';

const CustomerOrders = ({ customerId, setErrorMessage }) => {
  const [orders, setOrders] = useState(null);
  const [totalCreditUsed, setTotalCreditUsed] = useState(null);

  useEffect(() => {
    const getOrders = async (id) => {
      const result = await getOrdersByCustomer(id);
      if (result.orders) {
        const { orders } = result;
        orders.sort((a, b) => {
          if (DateTime.fromISO(a.createdAt) < DateTime.fromISO(b.createdAt)) {
            return 1;
          }

          if (DateTime.fromISO(a.createdAt) > DateTime.fromISO(b.createdAt)) {
            return -1;
          }

          return 0;
        });

        const c = orders.reduce((total, order) => {
          if (order.paymentSummary && order.paymentSummary.creditUsed) {
            return total + order.paymentSummary.creditUsed;
          }
          return total;
        }, 0);

        setTotalCreditUsed(c);
        setOrders(orders);
      } else {
        setErrorMessage('Unable to retrieve orders. Please wait and try again.');
      }
    };

    getOrders(customerId);
  }, [customerId, setErrorMessage]);

  return (
    <div className="card">
      <div className="card-header">
        <h4 className="card-header-title">Orders</h4>
      </div>
      <div className="card-body">
        {orders && (
          <div className="table-responsive">
            <table className="table table-sm table-nowrap card-table">
              <thead>
                <tr>
                  <th style={{ textAlign: 'left' }}>Order</th>
                  <th style={{ textAlign: 'left' }}>Total</th>
                  <th style={{ textAlign: 'left' }}>Date Placed</th>
                </tr>
              </thead>
              <tbody className="list font-size-base">
                {orders.map((order) => {
                  return (
                    <tr key={order.orderId}>
                      <td>
                        <span>
                          <Link to={{ pathname: `/orders/${order.orderId}` }}>{order.orderId}</Link>
                        </span>
                      </td>
                      <td>
                        <span>{order.totals && order.totals.length && order.totals[order.totals.length - 1].orderTotal}</span>
                      </td>
                      <td>
                        <span>{DateTime.fromISO(order.createdAt).toLocaleString(DateTime.DATETIME_SHORT)}</span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

CustomerOrders.propTypes = {
  customerId: PropTypes.string.isRequired,
  setErrorMessage: PropTypes.func.isRequired,
};

export default CustomerOrders;
