import React, { useState, useContext, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import Select from 'react-select';
import { useQueryCache, useQuery } from 'react-query';
import { Tooltip } from 'react-tippy';
import { Dialog, toaster, Popover, Menu, Position, Alert } from 'evergreen-ui';
import Spinner from '../common/Spinner';
import TextInput from '../common/TextInput';
import { AuthContext } from '../../context/authContext';
import { ADMIN_URL, NETSUITE_INVENTORY_FEATURE_FLAG, NETSUITE_URL, STICKY_URL } from '../../config';
import { getOrder, cancelOrder, getSuborderStatusMap, sendBloomreachEventOrEmail } from '../../services/OrderManagementService';
import { roleChecks } from '../../utilities/Role';
import OrderDetail from './OrderDetail';
import ChargeOrderForm from './ChargeOrderForm';
import AuditLog from '../auditLog/AuditLog';

const ORDER_LIFECYCLE_EVENT_TYPES = {
  ORDER_CONFIRMATION_EMAIL: 'order_confirmation_email',
  SHIPPING_CONFIRMATON_EMAIL: 'shipping_confirmation_email',
  OUT_FOR_DELIVERY_EVENT: 'out_for_delivery_event',
  DELIVERY_CONFIRMATION_EMAIL: 'delivery_confirmation_email',
  DELIVERY_EVENT: 'delivery_event',
  REDELIVERY_EVENT: 'redelivery_event',
  REFUND_EVENT: 'refund_event',
  CREDIT_EVENT: 'credit_event',
  CANCEL_ORDER_EVENT: 'cancel_order_event',
};

export const refundStrategies = ['full', 'none'];
export const deallocateStrategies = [
  { value: 'hold', label: 'Release to Buffer: Hold' },
  { value: 'delete', label: 'Release to Available for Sale' },
  { value: 'care', label: 'Release to Buffer: Care' },
  { value: 'reconcile', label: 'Release to Reconcile: Manual' },
];
export const cancelReasons = [
  {
    label: 'Customer requested order change',
    options: [
      { value: 'Duplicate Order', label: 'Duplicate order' },
      { value: 'Order Cancellation', label: 'Order cancellation' },
    ],
  },
  {
    label: 'Fraud',
    options: [{ value: 'Fraud', label: 'Fraud' }],
  },
];
export const REFUND_PERCENT = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 100];

const ViewOrder = () => {
  const queryCache = useQueryCache();
  const { id: orderId } = useParams();
  const [errorMessage, setErrorMessage] = useState('');
  const [order, setOrder] = useState(null);

  const [showCancelOrder, setShowCancelOrder] = useState(false);
  const [confirmCancelOrder, setConfirmCancelOrder] = useState(false);
  const [refundStrategy, setRefundStrategy] = useState('full');
  const [deallocateStrategy, setDeallocateStrategy] = useState('delete');
  const [reasonType, setReasonType] = useState('duplicateOrder');
  const [additionalDetails, setAdditionalDetails] = useState('');

  const [showChargeOrder, setShowChargeOrder] = useState(false);

  const [showSendConfirmationEmail, setShowSendConfirmationEmail] = useState(false);
  const [showSendDeliveryConfirmationEmail, setShowSendDeliveryConfirmationEmail] = useState(false);
  const [showSendShippedConfirmationEmail, setShowSendShippedConfirmationEmail] = useState(false);
  const [showSendOutForDeliveryEvent, setShowSendOutForDeliveryEvent] = useState(false);
  const [showSendRedeliveryEvent, setShowSendRedeliveryEvent] = useState(false);
  const [showSendCancelEvent, setShowSendCancelEvent] = useState(false);

  const [confirmationEmail, setConfirmationEmail] = useState(null);
  const [resendEmailError, setResendEmailError] = useState(null);

  const [showAuditLog, setShowAuditLog] = useState(false);
  const [auditLogId, setAuditLogId] = useState(null);
  const [defaultAuditLogSelect, setDefaultAuditLogSelect] = useState(null);

  const [suborderToSendEmailFor, setSuborderToSendEmailFor] = useState(null);

  const [isFetchingOrder, setIsFetchingOrder] = useState(false);

  const fetchOrder = async () => {
    if (!isFetchingOrder) {
      setIsFetchingOrder(true);
      const { success, order: fetchedOrder, errorMessage: fetchedErrorMessage } = await getOrder(orderId);

      if (success) {
        setOrder(fetchedOrder);
        setErrorMessage('');
        return;
      }
      const displayErrorMessage = fetchedErrorMessage || 'Please wait and try again.';
      setErrorMessage(`Unable to retrieve order. ${displayErrorMessage}`);

      setIsFetchingOrder(false);
    }
  };

  const { status } = useQuery(['orders', orderId], fetchOrder, { retry: false });

  useEffect(() => {
    if (!order && errorMessage.length === 0) {
      fetchOrder();
    }
  }, [order]);

  useEffect(() => {
    if (order) {
      setAuditLogId(order.orderId);
    }
  }, [order]);

  useEffect(() => {
    if (order) {
      setConfirmationEmail(order.customer.email);
    }
  }, [order]);

  const { userGroups } = useContext(AuthContext);

  const updateOrder = (e) => {
    e.preventDefault();
  };

  const handleCancelOrder = async () => {
    setConfirmCancelOrder(true);
    const reasons = [
      {
        tag: `Customer requested order change: ${reasonType}`,
        details: additionalDetails,
      },
    ];

    // override value if prepaid
    let overRideRefundStrategy = refundStrategy;
    if (!order?.subscription?.ongoing) {
      overRideRefundStrategy = 'none';
    }
    const result = await cancelOrder(orderId, overRideRefundStrategy, deallocateStrategy, reasons);
    if (result.success) {
      toaster.success('Order cancelled');
    } else {
      toaster.warning(result.message);
    }
    setShowCancelOrder(false);
    setConfirmCancelOrder(false);
    // queryCache.invalidateQueries(['orders', orderId]);
  };

  const resetEmailOrEventVars = () => {
    const showEmailFunctions = [
      setShowSendConfirmationEmail,
      setShowSendDeliveryConfirmationEmail,
      setShowSendShippedConfirmationEmail,
      setShowSendOutForDeliveryEvent,
      setShowSendRedeliveryEvent,
      setShowSendCancelEvent,
    ];

    for (const fcn of showEmailFunctions) {
      fcn(false);
    }

    setSuborderToSendEmailFor(null);
  };

  const handleSendOfEventOrEmail = async (data) => {
    const result = await sendBloomreachEventOrEmail(data);

    if (result.success) {
      toaster.success(`Successfully sent ${data.eventType}`);
      setResendEmailError(null);

      resetEmailOrEventVars();
    } else if (result.message) {
      setResendEmailError(`Resend confirmation failed: ${result.message}`);
    } else {
      setResendEmailError('Resend confirmation failed');
    }
  };

  const SubscriptionDetails = ({ subscription }) => {
    if (subscription.source) {
      if (subscription.source === 'aggregate') {
        return (
          <div className="ml-4">
            Subscription:
            <a href={`${ADMIN_URL}/subscriptions/${subscription.subscriptionNumber}`} target="_blank" className="btn btn-link" rel="noreferrer">
              {subscription.subscriptionNumber}
            </a>
          </div>
        );
      }
      if (subscription.source === 'sticky') {
        const url = `${STICKY_URL}/admin/orders.php`;
        return (
          <div className="ml-4">
            Subscription:
            <a
              href={`${url}?show_details=show_details&show_folder=view_all&fromPost=1&act=&sequence=1&show_by_id=${subscription.subscriptionNumber}`}
              target="_blank"
              className="btn btn-link"
              rel="noreferrer"
            >
              {subscription.subscriptionNumber}
            </a>
          </div>
        );
      }
    }

    return <div className="ml-4">Subscription: {subscription.subscriptionNumber}</div>;
  };

  const canChargeOrder = order && order.totals && order.totals.length && order.totals[order.totals.length - 1].orderTotal > 0;

  const getSubordersForSpecificBloomreachEvents = (eventType) => {
    const { suborders } = order;

    const filterOnSuborderListBy = (filterMechanism) => suborders.filter(filterMechanism);

    const statusMap = getSuborderStatusMap();

    switch (eventType) {
      case ORDER_LIFECYCLE_EVENT_TYPES.SHIPPING_CONFIRMATON_EMAIL:
        return filterOnSuborderListBy((suborder) => statusMap[suborder.status] >= statusMap.InTransit && suborder?.delivery?.transport === 'mail');
      case ORDER_LIFECYCLE_EVENT_TYPES.OUT_FOR_DELIVERY_EVENT:
        return filterOnSuborderListBy((suborder) => statusMap[suborder.status] >= statusMap.InTransit);
      case ORDER_LIFECYCLE_EVENT_TYPES.REDELIVERY_EVENT:
        return filterOnSuborderListBy((suborder) => suborder.redeliveryOfSuborderId);
      default:
        return [];
    }
  };

  const selectSuborderElement = (eventType) => {
    const options = getSubordersForSpecificBloomreachEvents(eventType).map((so) => ({ value: so.suborderId, label: so.suborderId }));

    return (
      <div className="mb-4">
        Select Suborder To Send Email For:
        <Select
          id={`selectSuborder-${eventType}`}
          onChange={(option) => setSuborderToSendEmailFor(option.value)}
          options={options}
          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
          menuPortalTarget={document.body}
          defaultValue={suborderToSendEmailFor || ''}
        />
      </div>
    );
  };

  const allowedToRenderShippingSend =
    order && order.suborders && getSubordersForSpecificBloomreachEvents(ORDER_LIFECYCLE_EVENT_TYPES.SHIPPING_CONFIRMATON_EMAIL).length > 0;

  const allowedToRenderOutForDeliverySend =
    order && order.suborders && getSubordersForSpecificBloomreachEvents(ORDER_LIFECYCLE_EVENT_TYPES.OUT_FOR_DELIVERY_EVENT).length > 0;

  const allowedToRenderDeliverySend = order && order.status === 'Delivered';

  const allowedToRenderRedeliverySend =
    order && order.suborders && getSubordersForSpecificBloomreachEvents(ORDER_LIFECYCLE_EVENT_TYPES.REDELIVERY_EVENT).length > 0;

  const allowedToRenderCancelSend = order && order.status === 'Cancelled';

  const orderRelatedEvent = ({ allowanceVariable, showDialogFunction, dialogTitle, eventType }) => {
    return (
      allowanceVariable && (
        <Dialog
          isShown={showDialogFunction}
          title={dialogTitle}
          onCloseComplete={resetEmailOrEventVars}
          onCancel={resetEmailOrEventVars}
          confirmLabel="Send"
          onConfirm={() => handleSendOfEventOrEmail({ orderId, email: confirmationEmail, eventType })}
          shouldCloseOnOverlayClick={false}
        >
          <div>
            {resendEmailError && (
              <div className="alert alert-danger" role="alert">
                {resendEmailError}
              </div>
            )}
          </div>
          <div>
            <div className="mb-2">For Order Id: {orderId}</div>
            {order && order.suborders && (
              <div>
                Suborders:
                {order.suborders.map((s) => {
                  return (
                    <div key={s.suborderId}>
                      {s.suborderId}
                      {s.redeliveryOfSuborderId && <span className="badge bg-info mx-2">Redelivery</span>}
                    </div>
                  );
                })}
              </div>
            )}
            <div className="mt-4">
              <TextInput
                label="Email"
                onChange={(event) => {
                  setConfirmationEmail(event.target.value);
                }}
                name="email"
                value={confirmationEmail}
                required
              />
            </div>
          </div>
        </Dialog>
      )
    );
  };

  const suborderRelatedEvent = ({ allowanceVariable, showDialogFunction, dialogTitle, eventType }) => {
    return (
      allowanceVariable && (
        <Dialog
          isShown={showDialogFunction}
          title={dialogTitle}
          onCloseComplete={resetEmailOrEventVars}
          onCancel={resetEmailOrEventVars}
          confirmLabel="Send"
          onConfirm={() =>
            handleSendOfEventOrEmail({
              orderId,
              suborderId: suborderToSendEmailFor,
              email: confirmationEmail,
              eventType,
            })
          }
          isConfirmDisabled={suborderToSendEmailFor === null}
          shouldCloseOnOverlayClick={false}
        >
          <div>
            {resendEmailError && (
              <div className="alert alert-danger" role="alert">
                {resendEmailError}
              </div>
            )}
          </div>
          {selectSuborderElement(eventType)}
          <div>
            <div className="mb-2">For Order Id: {orderId}</div>
            <div className="mt-4">
              <TextInput
                label="Email"
                onChange={(event) => {
                  setConfirmationEmail(event.target.value);
                }}
                name="email"
                value={confirmationEmail}
                required
              />
            </div>
          </div>
        </Dialog>
      )
    );
  };

  return (
    <div className="container-fluid">
      <div className="row justify-content-center">
        <div className="col-12">
          <div className="header">
            <div className="header-body">
              <div className="row align-items-center">
                <div className="col">
                  <h6 className="header-pretitle">Orders</h6>

                  <div className="header-title d-flex align-items-baseline">
                    <h1>{orderId}</h1>
                    <div>
                      <h1>
                        {order && (
                          <Tooltip title={order.statusDate} position="bottom" arrow="true">
                            <span className={`badge ${order.status === 'Completed' ? 'bg-success' : 'bg-light text-dark'} ml-3`}>
                              {order.status}{' '}
                            </span>
                          </Tooltip>
                        )}
                      </h1>
                    </div>
                    {order && order.type && <div className="ml-4">Type: {order.type}</div>}
                    {order && order.netsuite && order.netsuite.salesOrderId && (
                      <div className="ml-4">
                        <a
                          href={`${NETSUITE_URL}/app/accounting/transactions/salesord.nl?id=${order.netsuite.salesOrderId}&whence=`}
                          target="_blank"
                          className="btn btn-link"
                          rel="noreferrer"
                        >
                          Netsuite Sales Order
                        </a>
                      </div>
                    )}
                    {order && order.redeliveryOfOrderId && (
                      <div className="ml-4">
                        <Link to={{ pathname: `/orders/${order.redeliveryOfOrderId}` }} target="_blank">
                          Redelivery Of Order {order.redeliveryOfOrderId}
                        </Link>
                      </div>
                    )}
                    {order && order.subscription && <SubscriptionDetails subscription={order.subscription} />}
                  </div>
                  {order && order.bulkUploadRequestId && (
                    <div className="mb-3">
                      Bulk Upload: <Link to={{ pathname: `/orders/bulk/${order.bulkUploadRequestId}` }}>{order.bulkUploadRequestId}</Link>
                    </div>
                  )}
                </div>
                <div className="col-auto">
                  <Dialog
                    isShown={showCancelOrder}
                    title="Cancel Order"
                    onCloseComplete={() => {
                      setShowCancelOrder(false);
                      setRefundStrategy('full');
                      setDeallocateStrategy('delete');
                      setReasonType('duplicateOrder');
                      setAdditionalDetails('');
                    }}
                    confirmLabel="Confirm"
                    isConfirmDisabled={confirmCancelOrder}
                    onConfirm={handleCancelOrder}
                  >
                    <div>
                      <div className="mb-4">
                        Choose Refund Strategy:
                        {/* Show on both ongoing and not ongoing subscription */}
                        {order?.subscriptionId ? (
                          <div>
                            <Alert
                              intent="none"
                              title={'Use original subscription order to refund if needed. Use "Cancel subscription" button to affect future orders.'}
                              marginBottom={10}
                              marginTop={10}
                            />
                            <Select
                              id="selectRefundType"
                              defaultValue={{ value: 'none', label: 'None' }}
                              isDisabled
                              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                              menuPortalTarget={document.body}
                            />
                          </div>
                        ) : (
                          <Select
                            id="selectRefundType"
                            defaultValue={{ value: refundStrategy, label: refundStrategy }}
                            onChange={(event) => setRefundStrategy(event.value)}
                            options={refundStrategies.map((option) => {
                              return { value: option, label: option };
                            })}
                            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                            menuPortalTarget={document.body}
                          />
                        )}
                      </div>
                      {!NETSUITE_INVENTORY_FEATURE_FLAG && (
                        <div className="mb-4">
                          What should we do with the inventory?
                          <Select
                            id="selectDeallocateType"
                            defaultValue={deallocateStrategies.find((x) => x.value === deallocateStrategy)}
                            onChange={(event) => setDeallocateStrategy(event.value)}
                            options={deallocateStrategies}
                            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                            menuPortalTarget={document.body}
                          />
                        </div>
                      )}
                      <div className="mb-4">
                        Select reason for cancelling the order:
                        <Select
                          id="reasonType"
                          onChange={(event) => setReasonType(event.value)}
                          options={cancelReasons}
                          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                          menuPortalTarget={document.body}
                        />
                      </div>
                      <div>
                        Provide any additional details for the reason:
                        <TextInput
                          width="100%"
                          name="additionalDetailsStrategy"
                          onChange={(event) => setAdditionalDetails(event.target.value)}
                          value={additionalDetails}
                        />
                      </div>
                    </div>
                  </Dialog>

                  {orderRelatedEvent({
                    allowanceVariable: true,
                    showDialogFunction: showSendConfirmationEmail,
                    dialogTitle: 'Send Order Confirmation Email',
                    eventType: ORDER_LIFECYCLE_EVENT_TYPES.ORDER_CONFIRMATION_EMAIL,
                  })}

                  {suborderRelatedEvent({
                    allowanceVariable: allowedToRenderShippingSend,
                    showDialogFunction: showSendShippedConfirmationEmail,
                    dialogTitle: 'Send Shipped Confirmation Email',
                    eventType: ORDER_LIFECYCLE_EVENT_TYPES.SHIPPING_CONFIRMATON_EMAIL,
                  })}

                  {suborderRelatedEvent({
                    allowanceVariable: allowedToRenderOutForDeliverySend,
                    showDialogFunction: showSendOutForDeliveryEvent,
                    dialogTitle: 'Send Out For Delivery Event',
                    eventType: ORDER_LIFECYCLE_EVENT_TYPES.OUT_FOR_DELIVERY_EVENT,
                  })}

                  {orderRelatedEvent({
                    allowanceVariable: allowedToRenderDeliverySend,
                    showDialogFunction: showSendDeliveryConfirmationEmail,
                    dialogTitle: 'Send Delivery Confirmation Email',
                    eventType: ORDER_LIFECYCLE_EVENT_TYPES.DELIVERY_CONFIRMATION_EMAIL,
                  })}

                  {suborderRelatedEvent({
                    allowanceVariable: allowedToRenderRedeliverySend,
                    showDialogFunction: showSendRedeliveryEvent,
                    dialogTitle: 'Send Redelivery Event',
                    eventType: ORDER_LIFECYCLE_EVENT_TYPES.REDELIVERY_EVENT,
                  })}

                  {orderRelatedEvent({
                    allowanceVariable: allowedToRenderCancelSend,
                    showDialogFunction: showSendCancelEvent,
                    dialogTitle: 'Send Cancel Order Event',
                    eventType: ORDER_LIFECYCLE_EVENT_TYPES.CANCEL_ORDER_EVENT,
                  })}

                  <AuditLog
                    entityType="ORDER"
                    id={auditLogId}
                    entity={order}
                    showAuditLog={showAuditLog}
                    setShowAuditLog={setShowAuditLog}
                    defaultSelect={defaultAuditLogSelect}
                    setDefaultAuditLogSelect={setDefaultAuditLogSelect}
                  />

                  {canChargeOrder && (
                    <ChargeOrderForm
                      order={order}
                      showChargeOrder={showChargeOrder}
                      setShowChargeOrder={setShowChargeOrder}
                      canManuallySetChargeAmount={roleChecks.canChangeAmountWhileChargingOrder(userGroups)}
                      queryCache={queryCache}
                    />
                  )}

                  {roleChecks.canAddOrUpdateOrders(userGroups) && (
                    <>
                      <button type="button" className="btn btn-danger mr-2" onClick={() => setShowCancelOrder(true)}>
                        Cancel Order
                      </button>

                      <Popover
                        position={Position.BOTTOM_LEFT}
                        content={
                          <Menu>
                            <Menu.Group>
                              <Menu.Item onSelect={() => setShowSendConfirmationEmail(true)}>Send Order Confirmation Email</Menu.Item>
                              {allowedToRenderShippingSend && (
                                <Menu.Item onSelect={() => setShowSendShippedConfirmationEmail(true)}>Send Shipped Confirmation Email</Menu.Item>
                              )}
                              {allowedToRenderOutForDeliverySend && (
                                <Menu.Item onSelect={() => setShowSendOutForDeliveryEvent(true)}>Send Out For Delivery Event</Menu.Item>
                              )}
                              {allowedToRenderDeliverySend && (
                                <Menu.Item onSelect={() => setShowSendDeliveryConfirmationEmail(true)}>Send Delivery Confirmation Email</Menu.Item>
                              )}
                              {allowedToRenderRedeliverySend && (
                                <Menu.Item onSelect={() => setShowSendRedeliveryEvent(true)}>Send Redelivery Event</Menu.Item>
                              )}
                              {allowedToRenderCancelSend && <Menu.Item onSelect={() => setShowSendCancelEvent(true)}>Send Cancel Event</Menu.Item>}
                              <Menu.Item
                                onSelect={() => {
                                  setShowAuditLog(true);
                                }}
                              >
                                View Audit Log
                              </Menu.Item>
                            </Menu.Group>
                          </Menu>
                        }
                      >
                        <button type="button" className="btn btn-secondary mr-2">
                          Other Actions
                        </button>
                      </Popover>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          {status !== 'loading' && (
            <form className="mb-4" onSubmit={updateOrder}>
              {order && (
                <OrderDetail
                  order={order}
                  setOrder={setOrder}
                  queryCache={queryCache}
                  setShowAuditLog={setShowAuditLog}
                  setDefaultAuditLogSelect={setDefaultAuditLogSelect}
                  canChargeOrder={canChargeOrder}
                  setShowChargeOrder={setShowChargeOrder}
                />
              )}
              {errorMessage && (
                <div className="alert alert-danger" role="alert">
                  {errorMessage}
                </div>
              )}
            </form>
          )}
          {status === 'loading' && <Spinner />}
        </div>
      </div>
    </div>
  );
};

export default ViewOrder;
