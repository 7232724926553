import { getCurrentJwtToken } from './AuthIdentity';
import { ORDER_MANAGEMENT_SERVICE_BASE_URL, PICK_AND_PASS_FEATURE_FLAG } from '../config';
import getComponentDetailsForKitDisplayInOnePager from '../components/helpers/getComponentDetailsForKitDisplayInOnePager';

const popupWindowParameters = 'location=no,status=no,scrollbars=no,menubar=no,resizable=no,width=700,height=700';

export async function printOnePager(suborders, customer = null) {
  const printErrors = [];

  const w = window.open('/one-pager-notecard-and-labels.html', 'massprint-note', popupWindowParameters);

  if (customer) w.customer = customer;
  w.orders = suborders;
  w.getComponentDetailsForKitDisplayInOnePager = getComponentDetailsForKitDisplayInOnePager;
  w.apiKey = await getCurrentJwtToken();
  w.url = `${ORDER_MANAGEMENT_SERVICE_BASE_URL}`;
  w.pickAndPassFeatureFlag = PICK_AND_PASS_FEATURE_FLAG;
  w.onload = () => w.init();
  w.onafterprint = () => {
    if (w.suborderErrors) {
      printErrors.push(...w.suborderErrors);

      const errorsEl = w.document.getElementById('errors');
      printErrors.forEach((error) => {
        const span = w.document.createElement('h3');
        span.innerText = `${error.orderNumber}: ${error.message.toString()}`;
        errorsEl.appendChild(span);
        // This class will push down the printed content so the errors have focus
        errorsEl.className = 'take-screen-priority';
      });
    } else {
      return w.close();
    }
    return true;
  };
}
