const getProductTypeFromClassificationOrCategory = (product) => {
  const classification = product.classification || '';
  const category = product.category || '';
  if (classification && classification !== '') {
    switch (classification) {
      case 'Fresh Florals': // new
      case 'Floral - Live':
        return 'Floral';
      case 'Wreaths & Greenery': // new
      case 'Dried Florals': // new
      case 'Floral - Dried, Wreaths & Home':
        return 'Dried/Wreath';
      case 'Plants':
        return 'Plant';
      case 'Vessels': // new
      case 'Vases & Pots':
        return 'Vase';
      case 'Hard Goods': // new
      case 'Food & Drink': // new
      case 'Gift Boxes': // new
      case 'Misc Addon':
        return 'Addon';
      default:
        return null; // don't name the type
    }
  }

  if (category) {
    switch (category) {
      case 'flowers':
        return 'Floral';
      case 'dried-bouquets':
        return 'Dried/Wreath';
      case 'plants':
        return 'Plant';
      case 'vessels':
        return 'Vase';
      case 'gifts':
        return 'Addon';
      default:
        return 'Other';
    }
  }

  return 'Other';
};

export default getProductTypeFromClassificationOrCategory;
