import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getCustomer } from '../../services/OrderManagementService';
import CustomerOrders from './CustomerOrders';
import CustomerCredits from './CustomerCredits';
import { REACT_APP_SHOPIFY_ADMIN_URL } from '../../config';

const ViewCustomer = () => {
  const { id: customerId } = useParams();

  const [customer, setCustomer] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    const getCustomerById = async () => {
      const result = await getCustomer(customerId);

      if (result.success) {
        setCustomer(result.customer);
      } else {
        const displayErrorMessage = result.errorMessage || 'Please wait and try again.';
        setErrorMessage(`Unable to retrieve customer. ${displayErrorMessage}`);
      }
    };

    getCustomerById();
  }, [customerId]);

  let customerLink = '#';
  if (customer?.shopifyId) {
    const shopifyIdLastPart = customer.shopifyId.substring(customer.shopifyId.lastIndexOf('/') + 1);
    customerLink = `${REACT_APP_SHOPIFY_ADMIN_URL}/customers/${shopifyIdLastPart}`;
  }

  return (
    <div className="container-fluid">
      <div className="row justify-content-center">
        <div className="col-12">
          <div className="header">
            <div className="header-body">
              <div className="row align-items-center">
                <div className="col">
                  <h6 className="header-pretitle">Customers</h6>
                  <h1 className="header-title">
                    <span>{customerId}</span>
                  </h1>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            {errorMessage && (
              <div className="alert alert-danger" role="alert">
                {errorMessage}
              </div>
            )}
            <div className="col">
              {customer && (
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-header-title">Customer Details</h4>
                  </div>
                  <div className="card-body">
                    <h4 className="mb-1">
                      {customer.firstName} {customer.lastName}
                    </h4>
                    <div className="mb-1">
                      {customer.parentCustomerName && <div>{customer.parentCustomerName}</div>}
                      <div>
                        <a href={customerLink} rel="noreferrer" target="_blank">
                          {customer.email}
                        </a>
                      </div>
                      <div>{customer.phone}</div>
                      {customer.zipCode && <div>{customer.zipCode}</div>}
                      {customer.customerGroups && (
                        <div>
                          {customer.customerGroups.map((e, index) => (
                            <div className="badge bg-dark text-white ml-3" key={`customer-group-${index}`}>
                              {e}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
            {(customer) && (
              <div className="col">
                <CustomerCredits
                  customerId={customer.customerId}
                  setErrorMessage={setErrorMessage}
                  customerEmail={customer.email}
                />
              </div>
            )}

            {customer && <CustomerOrders customerId={customer.customerId} setErrorMessage={setErrorMessage} />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewCustomer;
