import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { deleteCreditById } from '../../../services/OrderManagementService';
import Modal from '../../common/Modal';

const DeleteCreditModal = ({ credit, closeModal, successActions }) => {
  const [errorMessage, setErrorMessage] = useState(null);

  const onConfirm = async () => {
    const response = await deleteCreditById(credit.id, credit.reason, credit.tag);
    if (response.success) {
      successActions();
      closeModal();
    } else {
      setErrorMessage('Got error from backend.');
    }
  };

  return (
    <Modal title={`Delete Batch ${credit.id}`} onConfirm={onConfirm} onClose={closeModal} onCancel={closeModal} visible>
      {errorMessage === null ? (
        <div>Are you sure you want to delete this credit?</div>
      ) : (
        <>
          <div>Could not delete this credit due to the following error:</div>
          <div className="text-danger">{errorMessage}</div>
        </>
      )}
    </Modal>
  );
};

DeleteCreditModal.propTypes = {
  credit: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired,
  successActions: PropTypes.func.isRequired,
};

export default DeleteCreditModal;
