import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import { Button, PlusIcon, Spinner, toaster } from 'evergreen-ui';
import { getCreditsByCustomer } from '../../services/OrderManagementService';
import IssueOrEditCreditsModal from './credits/IssueOrEditCreditsModal';
import DeleteCreditModal from './credits/DeleteCreditModal';
import { NETSUITE_INVENTORY_FEATURE_FLAG, REACT_APP_SHOPIFY_ADMIN_URL } from '../../config';

const CustomerCredits = ({ customerId, setErrorMessage, customerEmail }) => {
  const [credits, setCredits] = useState(null);
  const [creditToEdit, setCreditToEdit] = useState(null);
  const [isCreatingCredit, setIsCreatingCredit] = useState(false);
  const [creditToDelete, setCreditToDelete] = useState(null);
  const [loading, setLoading] = useState(false);

  const getCredits = async () => {
    setLoading(true);
    if (customerId) {
      const result = await getCreditsByCustomer(customerId);
      if (result.credits) {
        setCredits(result.credits);
        setLoading(false);
      } else {
        setErrorMessage('Unable to retrieve credits. Please wait and try again.');
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    getCredits();
  }, [customerId, setErrorMessage]);

  const successActionsForCreatingOrEditingCredit = async (isCreate) => {
    await getCredits();
    toaster.success(`Successfully ${isCreate ? 'created' : 'edited'} credit.`);
  };

  const successActionsForDeletingCredit = async () => {
    await getCredits();
    toaster.success('Successfully deleted credit.');
  };
  const isNetSuiteInventoryFeatureEnabled = NETSUITE_INVENTORY_FEATURE_FLAG === 'true';

  return (
    <div className="card">
      <div className="card-header">
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ justifyContent: 'left' }}>
            <h4 className="card-header-title mt-2">Credits</h4>
          </div>
          <div style={{ justifyContent: 'right' }}>
            {(!isNetSuiteInventoryFeatureEnabled) && (
              <Button intent="success" iconAfter={PlusIcon} onClick={() => setIsCreatingCredit(true)}>
                Issue Credits
              </Button>
            )}
          </div>
        </div>
      </div>
      {!loading ?
        <div className="card-body">
          {credits &&
          credits
            .map((credit, index) => {
              return (
                <div key={`credit${credit.id}`}>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ justifyContent: 'left' }}>
                      <div>
                        ID:
                        <a href={`${REACT_APP_SHOPIFY_ADMIN_URL}/gift_cards/${credit.id}`} rel="noreferrer" target="_blank">
                          {credit.id}
                        </a>
                      </div>
                      <div>Remaining Amount: ${credit.balance} of ${credit.initial}</div>
                      <div>Expiration Date: {credit.exp ? DateTime.fromISO(credit.exp).toFormat('MMM. d, yyyy') : 'No expiration'}</div>
                    </div>
                  </div>
                  {index !== credits.length - 1 && <hr />}
                </div>
              );
            })}
        </div>
      :
        <div
          className="card-body"
          style={{ display: 'flex',
          justifyContent: 'center' }}
        >
          <Spinner />
        </div>}

      {creditToEdit && (
        <IssueOrEditCreditsModal
          credit={creditToEdit}
          customerEmail={customerEmail}
          closeModal={() => setCreditToEdit(null)}
          successActions={() => successActionsForCreatingOrEditingCredit(false)}
        />
      )}
      {isCreatingCredit && (
        <IssueOrEditCreditsModal
          customerEmail={customerEmail}
          closeModal={() => setIsCreatingCredit(false)}
          successActions={() => successActionsForCreatingOrEditingCredit(true)}
        />
      )}
      {creditToDelete && (
        <DeleteCreditModal credit={creditToDelete} closeModal={() => setCreditToDelete(null)} successActions={successActionsForDeletingCredit} />
      )}
    </div>
  );
};

CustomerCredits.propTypes = {
  customerId: PropTypes.string.isRequired,
  setErrorMessage: PropTypes.func.isRequired,
  customerEmail: PropTypes.string.isRequired,
};

export default CustomerCredits;
