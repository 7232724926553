import axios from 'axios';
import { toaster } from 'evergreen-ui';

import { getCurrentJwtToken } from './AuthIdentity';
import sortSubordersByBatchIndex from '../components/batching/helpers/sortSubordersByBatchIndex';
import { ORDER_MANAGEMENT_SERVICE_BASE_URL } from '../config';
import { updateBatchStatus, updateBatchSuborderStatus, getBatch } from '../services/OrderManagementService';
import { printOnePager } from './Printing';
import { applyOpsNamesToLineItemsAndComponentsInSuborder } from './OpsNames';
import getComponentDetailsForKitDisplayInOnePager from '../components/helpers/getComponentDetailsForKitDisplayInOnePager';
import getComponentsForDoubleOrTriple from '../components/helpers/getComponentsForDoubleOrTriple';

const popupWindowParameters = 'location=no,status=no,scrollbars=no,menubar=no,resizable=no,width=700,height=700';

const getBatchAndAddOpsNames = async (batchId, productFeed) => {
  const { batch } = await getBatch(batchId);
  sortSubordersByBatchIndex(batch);
  batch.suborders.forEach((suborder) => {
    if (suborder.lineItems) {
      applyOpsNamesToLineItemsAndComponentsInSuborder(suborder, productFeed);
    }
  });
  return batch;
};

export async function downloadIPDFile(batchId, currStatus, queryCache) {
  const { data } = await axios({
    url: `${ORDER_MANAGEMENT_SERVICE_BASE_URL}/batches/${batchId}/ipd-manifest`,
    method: 'GET',
    responseType: 'blob',
  });
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', 'urbanstems-ipd.in');
  link.click();
  window.URL.revokeObjectURL(url);

  if (currStatus === 'WorkorderPrinted' || currStatus === 'Created') {
    await Promise.allSettled([updateBatchStatus(batchId, 'SubordersPrinted'), updateBatchSuborderStatus(batchId, 'SuborderPrinted')]);
    queryCache.invalidateQueries('fulfillmentSuborders');
    queryCache.invalidateQueries('batches');
  }
}

export async function printBatchWorkorder(batchId, currStatus, productFeed, fcConfig, queryCache) {
  const batch = await getBatchAndAddOpsNames(batchId, productFeed);

  sortSubordersByBatchIndex(batch);
  const w = window.open('/workorder.html', 'print-workorder', popupWindowParameters);
  w.batch = batch;
  w.fc = fcConfig;
  w.getComponentsForDoubleOrTriple = getComponentsForDoubleOrTriple;
  w.apiKey = await getCurrentJwtToken();
  w.onload = () => w.init();
  w.onafterprint = () => {
    return w.close();
  };

  if (currStatus === 'Created') {
    await Promise.allSettled([updateBatchStatus(batchId, 'WorkorderPrinted'), updateBatchSuborderStatus(batchId, 'BatchWorkorderPrinted')]);
    queryCache.invalidateQueries('fulfillmentSuborders');
    queryCache.invalidateQueries('batches');
  }
}

export async function printBatchNotecards(batchId) {
  const { data } = await axios.get(`${ORDER_MANAGEMENT_SERVICE_BASE_URL}/batches/${batchId}`);
  const w = window.open('/notecards.html', 'massprint-note', popupWindowParameters);
  sortSubordersByBatchIndex(data.data.batch);
  w.orders = data.data.batch.suborders;
  w.apiKey = await getCurrentJwtToken();
  w.url = `${ORDER_MANAGEMENT_SERVICE_BASE_URL}`;
  w.onload = () => w.init();
  w.onafterprint = () => {
    return w.close();
  };
}

export async function printBatchLabels(batchId, currStatus, queryCache, fcConfig) {
  const { data } = await axios.get(`${ORDER_MANAGEMENT_SERVICE_BASE_URL}/batches/${batchId}`);
  const { batch } = data.data;
  const fileToUse = fcConfig.useCustomOnePagerForCdl ? '/one-pager-labels-custom-cdl.html' : '/one-pager-labels.html';
  const w = window.open(fileToUse, 'massprint-labels', popupWindowParameters);
  sortSubordersByBatchIndex(data.data.batch);
  w.getComponentDetailsForKitDisplayInOnePager = getComponentDetailsForKitDisplayInOnePager;
  w.orders = batch.suborders;
  w.apiKey = await getCurrentJwtToken();
  w.url = `${ORDER_MANAGEMENT_SERVICE_BASE_URL}`;
  w.onload = () => w.init();
  w.onafterprint = () => {
    return w.close();
  };

  if (currStatus === 'WorkorderPrinted' || currStatus === 'Created') {
    await Promise.allSettled([updateBatchStatus(batchId, 'SubordersPrinted'), updateBatchSuborderStatus(batchId, 'SuborderPrinted')]);
    queryCache.invalidateQueries('fulfillmentSuborders');
    queryCache.invalidateQueries('batches');
  }
}

export async function printBatchOnePager(batchId, currStatus, productFeed, queryCache) {
  const batch = await getBatchAndAddOpsNames(batchId, productFeed);
  if (!batch) {
    toaster.danger('No batch found');
    return false;
  }
  await printOnePager(batch.suborders);

  if (currStatus === 'WorkorderPrinted' || currStatus === 'Created') {
    await Promise.allSettled([updateBatchStatus(batchId, 'SubordersPrinted'), updateBatchSuborderStatus(batchId, 'SuborderPrinted')]);
    queryCache.invalidateQueries('fulfillmentSuborders');
    queryCache.invalidateQueries('batches');
  }
  return true;
}

export async function printUpsWorldeaseMasterInvoice(batchId) {
  const { data } = (await axios.get(`${ORDER_MANAGEMENT_SERVICE_BASE_URL}/batches/${batchId}/ups-worldease-master-invoice-data`)).data;
  const w = window.open('/ups-worldease-master-invoice.html', 'ups-worldease-master-invoice', popupWindowParameters);
  w.data = data;
  w.apiKey = await getCurrentJwtToken();
  w.onload = () => w.init();
  w.onafterprint = () => {
    return w.close();
  };
}

export async function completeBatch(batchId, queryCache) {
  const response = await Promise.allSettled([updateBatchStatus(batchId, 'Completed'), updateBatchSuborderStatus(batchId, 'AwaitingPickup')]);
  if (queryCache) {
    queryCache.invalidateQueries('fulfillmentSuborders');
    queryCache.invalidateQueries('batches');
  }
  return response;
}

export async function downloadBatchSubordersCsv(batch) {
  const suborderObj = batch.suborders.map((s) => {
    let trackingCode = '';
    if (s.delivery.transportVendorDelivery.name.toLowerCase() === 'onfleet') {
      trackingCode = s.delivery.transportVendorDelivery.onfleetTaskId;
    } else if (s.delivery.transportVendorDelivery.name === 'easypost') {
      trackingCode = s.delivery.transportVendorDelivery.trackingCode;
    }

    return {
      orderId: s.orderId,
      suborderId: s.suborderId,
      trackingNumber: trackingCode,
      fulfillmentDate: s.delivery.orderFulfilledBy,
      deliveryDate: s.delivery.startTime,
    };
  });

  let csv = '';

  const fields = Object.keys(suborderObj[0]);

  csv = suborderObj.map((s) => {
    return fields
      .map((fieldName) => {
        return JSON.stringify(s[fieldName], (key, value) => {
          return value === null || value === undefined ? '' : value;
        });
      })
      .join(',');
  });
  csv.unshift(fields.join(','));
  csv = csv.join('\r\n');

  const { batchId } = batch;

  const url = window.URL.createObjectURL(new Blob([csv]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `batch-suborders-${batchId}.csv`);
  link.click();
  window.URL.revokeObjectURL(url);
}
