const skuNameQuantityPrefixArray = [
  {
    skuPrefix: 'FLRL-D',
    quantityToMultiply: 2,
    namePrefix: 'Double ',
  },
  {
    skuPrefix: 'FLRL-T',
    quantityToMultiply: 3,
    namePrefix: 'Triple ',
  },
];

const isDoubleOrTriple = (product) => {
  if (!product.sku) {
    return false;
  }

  return skuNameQuantityPrefixArray.some((skuQuantityElement) => product.sku.includes(skuQuantityElement.skuPrefix));
};

const getComponentsForDoubleOrTriple = (product) => {
  if (isDoubleOrTriple(product)) {
    for (const skuQuantityElement of skuNameQuantityPrefixArray) {
      if (product.sku.includes(skuQuantityElement.skuPrefix)) {
        const name = product.name.replace(skuQuantityElement.namePrefix, '');
        return [
          {
            index: 0,
            quantity: skuQuantityElement.quantityToMultiply,
            sku: product.sku.replace(skuQuantityElement.skuPrefix, 'FLRL-B'),
            price: product.unitPrice && product.unitPrice > 0 ? product.unitPrice / skuQuantityElement.quantityToMultiply : 0,
            name,
            opsName: name,
          },
        ];
      }
    }
  }
  return null;
};

export default getComponentsForDoubleOrTriple;
