import getComponentsForDoubleOrTriple from './getComponentsForDoubleOrTriple';

const getComponentDetails = (component) => {
  return `
    <p>
      <span class="bold">${component.quantity}x</span>&nbsp;${component.name}
      <span class="text-muted">${component.sku}</span>
    </p>`;
};

const getComponentDetailsForKitDisplayInOnePager = (component) => {
  const granuluarComponents = getComponentsForDoubleOrTriple(component);

  if (granuluarComponents && granuluarComponents.length) {
    return getComponentDetails(granuluarComponents[0]);
  }

  return getComponentDetails(component);
};

export default getComponentDetailsForKitDisplayInOnePager;
